import React from "react";
import footerStyles from "./footer.module.css"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

export default function Footer() {
  return (
  <Container fluid className={footerStyles.footerhead}>
    <Container fluid="xl">
    <Row>
      <Col md={6} className={footerStyles.footercol}>
        <h3 className={footerStyles.primary}>Onigitop.CO.LTD</h3>
        <p className={footerStyles.primary}>บริษัท โอนิจิท็อป จำกัด</p>
        <p className={footerStyles.leftcontent}>รับทำเว็บไซต์ เว็บแอพพลิเคชั่น ให้กับนักการตลาดที่ต้องการความสามารถของเว็บไซต์ขั้นสูง เพื่อนำไปใช้ประโยชน์ต่อการสร้างการเติบโตให้กับองค์กรหรือกิจการของท่าน</p>
      </Col>
      <Col md={3} className={footerStyles.footercol}>
        <h5>บริการของเรา</h5>
        <ul>
          <li><Link to="/#hero">รับทำเว็บไซต์</Link></li>
          <li><Link to="/#hero">รับดูแลการตลาดออนไลน์</Link></li>
          <li><Link to="/#price">แพ็กเกจราคา</Link></li>
          <li><Link to="/#portfolio">ผลงานของเรา</Link></li>
          <li><Link to="/#about">เกี่ยวกับเรา</Link></li>
          <li><Link to="/blog/">บทความ</Link></li>
        </ul>
      </Col>
      <Col md={3} className={footerStyles.footercol}>
        <h5>ติดต่อเรา</h5>
        <ul>
          <li>E-mail: <Link href="mailto:info@onigitop.com">info@onigitop.com</Link></li>
          <li>Tel: <Link href="tel:+66(0)80-204-5246">080-204-5246</Link></li>
          <li>Line: <Link rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank">@onigitop</Link></li>
        </ul>
      </Col>
    </Row>
    </Container>
    <Row>
      <Col className={footerStyles.footer}>© 2021 Copyright&nbsp;<Link to="/">Onigitop.com</Link></Col>
    </Row>
  </Container>
  );
}