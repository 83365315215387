import React, { useState } from "react"
import Logo from "../images/logo-web.png"
import { Navbar, Nav, NavDropdown, Button, Container } from "react-bootstrap"
import headerStyles from "./header.module.css"

export default function Header() {
  const [show, setShow] = useState(false);
  const showDropdown = (e)=>{
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
  return (
      <Navbar expand="lg" bg="light" sticky="top">
      <Container fluid="xl">
        <Navbar.Brand href="/"><img className={headerStyles.img} src={Logo} alt="onigitop" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
            <NavDropdown title="บริการ" id="collasible-nav-dropdown" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                  <NavDropdown.Item href="/#hero">รับทำเว็บไซต์</NavDropdown.Item>
                  <NavDropdown.Item href="/#hero">รับดูแลการตลาดออนไลน์</NavDropdown.Item>
                  <NavDropdown.Item href="/#price">แพ็กเกจราคา</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/#portfolio">ผลงาน</Nav.Link>
            <Nav.Link href="/blog/">บทความ</Nav.Link>
            <Nav.Link href="/#about">เกี่ยวกับเรา</Nav.Link>
            <Nav.Link href="/#contact">ติดต่อเรา</Nav.Link>
            </Nav>
            <Button rel="noreferrer" href="https://lin.ee/O1vC4Bk" target="_blank" variant="primary" size="lg">สอบถามเพิ่มเติม</Button>{' '}
        </Navbar.Collapse>
      </Container>
      </Navbar>
  );
}